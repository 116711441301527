@use 'styles/spacing' as spacing;
@use 'styles/colors' as colors;
@use 'styles/fonts' as fonts;

.header {
  width: '100%';
  position: sticky;
  top: 0;
  z-index: 100000;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileMenu {
  background-color: colors.$core-bg-dark;
  position: fixed;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 120000;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.miniHeader {
  width: 100%;
  height: spacing.$min-header-height;
  background-color: colors.$dark-grey;
  position: fixed;
  top: 0;
  z-index: 100000;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
}
.headerContainer {
  width: 89%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: spacing.$max-header-height;
}
.menu {
  @include fonts.headerText;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 40px;
  align-items: center;
}
.lastItem {
  color: colors.$core-yellow;
}
.lastItemMobile{
  color: colors.$core-yellow;
  margin-right: 24px;
  margin-bottom: 5px;
}
.closeIconWrapper{
  display: none;
}
.lastItemOnScroll {
  @include colors.base-button;
  transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  cursor: pointer;
  box-shadow: 0px 5px 15px rgba(13, 8, 37, 0.75);
  border-radius: 24.7089px;
  color: colors.$f-light-bg-header;
  font-size: 14px;
  padding: 11px 24px;
  &:hover{
    padding: 11px 32px;
  }
}
.hide {
  display: none;
}

.clickable {
  cursor: pointer;
}

.menuContainer {
  display: none;
}
.menuBar {
  display: none;
}
.flexDMobile{
  display: none;
}
.openMenuBar {
  @include fonts.mobileMenuItem;
  width: 100%;
  display: grid;
  justify-items: center;
  grid-gap: 40px;
}

.placeHolder {
  display: block;
  height: spacing.$max-header-height;
  width: 100%;
  background-color: transparent;
}
.authPlaceHolder {
  width: 160px;
}
.activeLinkIndicator {
  height: 3px;
  width: 60px;
  background-color: colors.$core-mid-purple;
  position: absolute;
  margin: auto;
  margin-top: 30px;
}
.scrolledActiveLink {
  background-color: colors.$core-bg-dark;
}
.linkWrapper {
  height: spacing.$min-header-height;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.mobileLinkWrapper {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 15px;
}
.mobileActiveLinkIndicator {
  height: 1px;
  width: 30px;
  background-color: colors.$core-mid-purple;
  margin: auto;
}
.avatarWrapper{
  display: flex;
  padding: 9px;
  border-radius: 50%;
  background-color:colors.$accent-lavender;
  align-items: center;
  justify-content: center;
}
.flexD{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.margin{
  margin-left: 8px;
  margin-right: 8px;
}
.pointer{
  cursor: pointer;
}
.anchorMenu{
  position: absolute;
  top: 50px;
  right: -25px;
  width:144px;
  background-color: colors.$core-white;
  color: colors.$f-light-bg-header;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  }
  .anchorMenu::after{
    content: '';
    position: absolute;
    bottom: 100%;
    right: 20px;
    width: 0;
    border-bottom: 10px solid colors.$core-white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }
  .menuItem{
    padding: 17px;
    cursor: pointer;

  }
  .marginR{
    margin-right: 8px;
    margin-top: 5px;
  }
  .logoutWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: colors.$gray-text-wrapper ;
    border-bottom-left-radius:8px;
    border-bottom-right-radius: 8px
  }
  .mobileDivader{
    height: 1px;
    width: 40px;
    background-color: colors.$accent-mid-grey;
    margin: auto;

  }
@media screen and (max-width: 1000px) {

  div#inner-wrap {
    height: 100%;
  }
  .menu {
    display: none;
  }
  .flexDMobile{
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .menuBar {
    all: unset;
    display: unset;
    cursor: pointer;
    margin-left: 20px;
  }
  .closeIcon{
    all: unset;
    display: unset;
    cursor: pointer;
  }
  .taliLogo {
    width: 75.2px;
    height: 24px;
  }
  .menuContainer {
    display: unset;
    position: fixed;
    top: spacing.$max-header-height;
    left: 0px;
    width: 100%;
    height: calc(100vh - spacing.$max-header-height);
  }
  .closeIconWrapper{
    width: 24px;
    height: 16px;
    display: flex;
    margin-left: 20px;
    justify-content: flex-end;
    align-items: center;
  }
  .mobileNotScrolled{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .lastItemOnScroll{
    font-size: 18px;
  }
}
