@use 'styles/colors' as colors;
@use 'styles/spacing' as spacing;

.container {
  @include colors.darkBg;
}
.titleContainer {
  width: 100%;
  height: calc(100vh - spacing.$max-header-height);
  padding-left: spacing.$column-margin;
  padding-right: spacing.$column-margin;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainTitle {
  color: colors.$f-dark-bg-header;
  font-size: 120px;
  font-family: 'Noto serif';
  text-align: center;
}
.titleBlock {
  width: 60%;
  margin-top: -(spacing.$max-header-height);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.submitBtn {
  cursor: pointer;
  @include colors.outline-button;
  transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  padding: 1px 2px;
  display: flex;
  align-items: center;
  outline: none;
  width: 100%;
  max-width: 179px;
  border-radius: 32px;
  font-size: 14px;
  height: 58px;
  background-origin: border-box;
  color: colors.$core-white;
  box-shadow: 0px 10px 20px rgba(136, 63, 133, 0.15);
  border: none;
  margin: auto;
  margin-top: 57px;
  &:hover {
    max-width: 200px;
  }
}
.btnContent {
  align-content: center;
  height: 54px;
  width: 100%;
  border-radius: 32px;
  background: colors.$core-deep-violet;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
  &:hover {
    padding: 20px 40px;
  }
}
@media screen and (max-width: 1000px) {
  .titleBlock {
    width: 70%;
  }
}
@media screen and (max-width: 600px) {
  .titleBlock {
    width: 95%;
  }
}
