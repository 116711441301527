// Core Brand
// Main brand colors leveraged to convey the brands identity. These colours are represented in page backgrounds, core compenents and interactive elements (such as buttons).
$core-bg-dark: #121521;
$core-bg-light: #F1F4FF;
$core-white: #FFFFFF;
$core-yellow: #FFB40D;
$core-deep-violet: #261935;
$core-mid-purple: #7E4F87;
$core-light-purple: #9183A1;
$core-blue: #4477E4;


$dark-grey: #121521cc;
$black-25: #888A90;
$black-50: #C4C4C7;
$white-10: rgba(255,255,255,0.1);
$white-25: rgba(255,255,255,0.25);
$yellow-15:rgba(255, 180, 13, 0.15);


// Typography
// These colors are used specifically for typographic purposes.
$f-light-bg-header: #000000;
$f-light-bg-paragraph: #373737;
$f-dark-bg-header: #FFFFFF;
$f-dark-bg-paragraph: #E7E7E7;

// Icon image and text Background and color
$icon-dark-bg: #373737;
$gray-text-wrapper: #e6e9f4;
$light-gray-text-wrapper: #F8F9FB;
$green-disabled-btn-bg: rgba(61, 163, 47, 0.2);
$green-disabled-btn-text: #2C8820;
$grey-disabled-btn-bg: #e7e8e9;
$append-text:#4D5059;
$img-wrapper: #E6EBFC;
$scroll-bg:#d9d9d9;


// Dark Accents
// These colours are used more sparingly in comparison to the core brand colours and reserved for enhancing the look of certain components.
$accent-wolf-grey: #41424F;
$accent-mid-grey: #BEBEBE;

// Light Accents
// These colours are used more sparingly in comparison to the core brand colours and reserved for enhancing the look of certain components.
$accent-lavender: #CFD1E5;
$accent-pearl: #E9E6EC;

// Statuses
// Status-specific colors with assigned meaning.
$status-error: #EE4B4B;
$status-success: #3DA32F;
$status-warning: #FF9E00;
$status-info: $core-mid-purple;
$toast-btn-bg:#ECF6EB;
$toast-btn-success:#3DA32F1A;
$toast-btn-success-hover:#3DA32F4D;
$toast-btn-success-active:#3DA32F66;
$toast-btn-warning:#FF9E001A;
$toast-btn-warning-hover:#FF9E004D;
$toast-btn-warning-active:#FF9E0066;
$toast-btn-error:#EE4B4B1A;
$toast-btn-error-hover:#EE4B4B4D;
$toast-btn-error-active:#EE4B4B66;
$toast-btn-info:#7E4F871A;
$toast-btn-info-hover:#7E4F874D;
$toast-btn-info-active:#7E4F8766;

//text-decoration
$yellow-underline:#ffd10d;

// Background Gradients
@mixin lightBg {
  background-color: $core-bg-light;
};

@mixin outline-button {
  background: linear-gradient(270deg, rgb(255, 179, 15) 2.18%, #a25ecb 72.63%);
};

@mixin base-button {
 background: linear-gradient(279.45deg, #ffd317 7.13%, #ffb40d 92.87%);
};

@mixin darkBg {
  background: url('/profile/bg_glow_static.svg'), $core-bg-dark;
  // background-color: $core-bg-dark;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
