// Height of the header bar
$max-header-height: 140px;
$min-header-height: 72px;

// Column Margins (left and right)
$column-margin: 12%;

$mobile-column-margin: min($column-margin, 30px);


// Maximum width of content
$max-content-width: 1088px;