@use 'styles/colors' as colors;

$header-font: 'Noto Serif';
$sub-header-font: 'Inter';
$body-font: 'Inter';

$small-header: 24px;

// Desktop Typography
// Used for the main title of the page
@mixin header1 {
  all:unset;
  font-family: $header-font;
  font-size: 48px;
  font-weight: 400;
  line-height: 65px;
  color: colors.$core-white;
}

// Used for test under the main title
@mixin header2 {
  font-family: $header-font;
  font-size: 36px;
  font-weight: 400;
  line-height: 49px;
}

// Used for section titles.
@mixin header3 {
  font-family: $header-font;
  font-size: 30px;
  font-weight: 400;
  line-height: 41px;
}

@mixin subHeader1 {
  font-family: $sub-header-font;
  font-size: 21px;
  font-weight: 600;
  line-height: 26px;
}

// Used for text in the body section
@mixin paragraph1 {
  font-family: $body-font;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}

$paragraph2-size: 14px;

@mixin paragraph2 {
  font-family: $body-font;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
}

@mixin paragraph2SemiBold {
  @include paragraph2;
  font-weight: 600;
}

// Mobile Typography

@mixin mobileHeader1 {
  font-family: $header-font;
  font-size: 42px;
  font-weight: 400;
  line-height: 57px;
}

// Used for test under the main title
@mixin mobileHeader2 {
  font-family: $header-font;
  font-size: 36px;
  font-weight: 400;
  line-height: 49px;
}

// Used for section titles.
@mixin mobileHeader3 {
  font-family: $header-font;
  font-size: 28px;
  font-weight: 400;
  line-height: 38px;
}

@mixin mobileSubHeader1 {
  font-family: $sub-header-font;
  font-size: 21px;
  font-weight: 600;
  line-height: 26px;
}

// Used for text in the body section
@mixin mobileParagraph1 {
  font-family: $body-font;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}

$paragraph2-size: 14px;

@mixin mobileParagraph2 {
  font-family: $body-font;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
@mixin btnText {
  transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  font-weight: 700;
  line-height: 138%;
  color: colors.$core-bg-dark;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  &:hover{
    padding: 20px 44px;
  }
}


@mixin mobileParagraph2SemiBold {
  @include paragraph2;
  font-weight: 600;
}

@mixin headerText {
  font-size: 14px;
  font-weight: 600;
  line-height: 155%;
  color: colors.$core-white;
}
@mixin mobileMenuItem {
  font-size: 21px;
  font-weight: 600;
  line-height: 25.41px;
  color: colors.$core-white;
}

@mixin submitButton {
  font-family: $body-font;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
@mixin copyright {
  font-family: $body-font;
  font-size: 12px;
  font-weight: 400;
}
@mixin input{
  outline: none;
  width: 364px;
  background: transparent;
  border-bottom-color: colors.$core-light-purple;
  border-top: none;
  border-right: none;
  border-left: none;
  }
@mixin miniTitle {
  font-size: 18px;
  font-weight:600;
  line-height: 21.78px
}